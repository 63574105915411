// Inputs - used on multiple files
$input-bg-color: #fff;
$input-color: $black;
$input-line-height: 1.5; // used for btn mixin, so when input is next to btn they have same height
$input-min-width: 100%;
$input-px: 10px; // left and right
$input-px-md: 10px; // left and right for md breakpoint
$input-py: 10px; // top and bottom
$input-pt: 10px; // top
$input-pb: 10px; // bottom
$input-py-md: 12px; // top and bottom for md breakpoint
$input-pt-md: 12px; // top
$input-pb-md: 12px; // bottom
$input-border-width: 1px;
$input-border-color: #707070;
$input-focus-border: $black;
$input-focus-outline: false;
$input-placeholder-color: $darkgray;
$input-border-radius: 0;
$input-transition: none;


@mixin input-placeholder() {
  &:-moz-placeholder {
    @content;
  }

  // Firefox 4-18
  &::-moz-placeholder {
    @content;
  }

  // Firefox 19+
  &:-ms-input-placeholder {
    @content;
  }

  // Internet Explorer 10+
  &::-webkit-input-placeholder {
    @content;
  }

  // Safari and Chrome
  &::placeholder {
    @content;
  }

  // Safari and Chrome
}


@mixin input-text {
  background: $input-bg-color;
  color: $input-color;
  line-height: $input-line-height; //same as button
  min-width: $input-min-width;
  padding: $input-pt $input-px $input-pb;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  transition: $input-transition;
  //@include experimental(appearance, none); //remove browser styling
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  display: block;
  font-size: 12px;

  @include input-placeholder {
    color: $input-placeholder-color;
    font-size: 16px;

    @include media-breakpoint-up(md) {
      font-size: 10px;
    }
  }

  ;

  &:focus {
    border-color: $input-focus-border;

    @if ($input-focus-outline==false) {
      outline: none;
    }
  }

  @include media-breakpoint-up(md) {
    padding: $input-pt-md $input-px-md $input-pb-md;
  }

  //required to disable auto zoom in iOS when font is less than 16px
  @media screen and (-webkit-min-device-pixel-ratio:0) and (max-width: 767px) {
    font-size: 16px !important;
  }
}

@mixin custom-select-block {
  //add to select wrapper
  background: #fff;
  background-image: linear-gradient(45deg, transparent 50%, $secondary 50%), linear-gradient(135deg, $secondary 50%, transparent 50%);
  background-position: calc(100% - 20px) 50%, calc(100% - 13px) 50%;
  background-size: 7px 7px, 7px 7px;
  background-repeat: no-repeat;
  position: relative;

  select {
    background: transparent;
    width: 100%; //make dropdown sit on top of select
    padding-right: 40px;
    //@include experimental(appearance, none); //remove browser styling
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }

  select::-ms-expand {
    display: none;
  }

  option {
    padding: 10px;
    display: block;
    border: 1px solid $black;
  }

  // &:after { // chevron icon
  // 	@include icon-font-properties;
  // 	content: $i-dropdown;
  // 	font-size: rem-calc(18px);
  // 	display: block;
  // 	position: absolute;
  // 	right: rem-calc(15px);
  // 	top: 50%;
  // 	z-index: -1;
  // 	@include translateY(-50%);
  // }
}