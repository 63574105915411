	// @font-face {
	// 		font-family: 'suisse_intlregular';
	// 		src: url('../fonts/suisseintl-regular-webfont.woff') format('woff'),
	// 				url('../fonts/suisseintl-regular-webfont.woff2')  format('woff2');
	// 		font-weight: normal;
	// 		font-style: normal;
	// }

	// @font-face {
	// 		font-family: 'suisse_intllight';
	// 		src: url('../fonts/suisseintl-light-webfont.woff2') format('woff2'),
	// 				url('../fonts/suisseintl-light-webfont.woff') format('woff');
	// 		font-weight: normal;
	// 		font-style: normal;

	// }


	::-moz-selection {
		background: $text-selection-bg-color;
		color: $text-selection-color;
		text-shadow: none;
	}

	::selection {
		background: $text-selection-bg-color;
		color: $text-selection-color;
		text-shadow: none;
	}

	*,
	*::before,
	*::after {
		box-sizing: inherit; // from html
	}

	html {
		font-family: $body-font-family;
		//font-size: $root-font-size;
		box-sizing: border-box;
		-webkit-tap-highlight-color: transparent;
		-ms-text-size-adjust: 100%;
		-ms-overflow-style: scrollbar;
		-webkit-text-size-adjust: 100%;
		scroll-behavior: smooth;
		// @include fluid( font-size, $mobile, $scaling, $root-font-size, $root-font-size, false, false ); // scale down
		//@include fluid( font-size, $scaling, $four-k, $root-font-size, $root-font-size * 2.4, true, false );
		// min-width: $container; if non-responsive
		// -webkit-font-smoothing: antialiased;
		//www.intridea.com/blog/2014/5/8/better-font-smoothing-in-chrome-on-mac-os-x
		// below code makes the font slightly bold and dark on mac. Use with testing
		// @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
		// only screen and ( min-device-pixel-ratio: 1.25),
		// only screen and ( min-resolution: 200dpi),
		// only screen and ( min-resolution: 1.25dppx)
		// {
		// 	-webkit-font-smoothing: subpixel-antialiased;
		// }
		//@include scrollbars(10px, #888, #e9e9e9); // add custom scrollbar
	}

	html {
		background: $secondary;
	}

	body {
		background: $secondary;
		color: $primary;
		font-size: $body-font-size;
		font-family: $body-font-family;
		font-weight: $body-font-weight;
		line-height: 1;
	}

	/*----------  Headings  ----------*/
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6 {
		font-family: $headings-font-family;
		color: $headings-color;
		margin: $headings-margin-top 0 $headings-margin-bottom 0;

		&:first-child {
			margin-top: 0;
		}
	}

	h1,
	.h1 {
		//@include fluid( font-size, $min-375, $desktop, 30px, 60px );
		font-size: 22px;
		font-weight: 300;
		line-height: 1.2;
		font-family: $suisse_intlregular;
	}

	@include media-breakpoint-up(xl) {

		h1,
		.h1 {
			font-size: 26px;
		}
	}

	h2,
	.h2 {
		//@include fluid( font-size, $min-375, $desktop, 24px, 45px );
		font-size: 20px;
		font-weight: 400;
		line-height: 1.2;

		@include media-breakpoint-up(xl) {
			font-size: 22px;
		}
	}

	h3,
	.h3 {
		//@include fluid( font-size, $min-375, $desktop, 20px, 32px );
		font-size: 18px;
		font-weight: 400;
		line-height: 1.2;

		@include media-breakpoint-up(xl) {
			font-size: 20px;
		}
	}

	h4,
	.h4 {
		//@include fluid( font-size, $min-375, $desktop, 18px, 24px );
		font-size: 16px;
		font-weight: 400;
		line-height: 1.2;

		@include media-breakpoint-up(xl) {
			font-size: 18px;
		}
	}

	h5,
	.h5 {
		//@include fluid( font-size, $min-375, $desktop, 18px, 18px );
		font-weight: 400;
		line-height: 1.5;
		font-size: 12px;
	}

	h6,
	.h6 {
		//@include fluid( font-size, $min-375, $desktop, 15px, 15px );
		font-weight: 400;
		line-height: 1.5;
		font-size: 10px;
	}

	/*----------  Links/Buttons  ----------*/
	a {
		color: $base-link-color;
		text-decoration: $base-link-decoration;

		&:hover {
			color: $base-link-hover-color;
			text-decoration: $base-link-hover-decoration;
		}

		&:focus {
			outline: none;
		}
	}

	button {
		&:focus {
			outline: rem-calc(3px) solid dodgerblue;
		}
	}

	button,
	input,
	optgroup,
	select,
	textarea {
		font-family: $body-font-family;
	}

	a,
	button,
	input[type="submit"],
	input[type="button"],
	input[type="reset"] {
		transition-property: background,
			color, border-color;
		transition-duration: .2s;
		border-radius: 0;
		-webkit-text-decoration-skip-ink: auto;
		-webkit-text-decoration-skip: objects;

		&:hover {
			transition-duration: .1s;
		}
	}

	/*----------  Content  ----------*/
	p,
	table,
	ul,
	ol,
	dl,
	dd,
	pre,
	blockquote,
	address,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		&:last-child {
			margin-bottom: 0;
		}
	}

	p,
	pre,
	blockquote,
	address,
	dd {
		line-height: $global-line-height;
	}

	p,
	table,
	ul,
	ol,
	dl,
	dd,
	pre,
	blockquote,
	address {
		margin: 0 0 $global-margin-bottom;
	}

	b,
	strong {
		font-weight: 700;
	}

	small {
		font-size: rem-calc(14px);
	}

	hr {
		margin-top: $global-margin-bottom;
		margin-bottom: $global-margin-bottom;
		border: 0;
		border-top: 1px solid #e5e5e5;
	}

	address {
		font-style: normal;
	}

	output {
		display: block;
		padding-top: rem-calc(30px);
		line-height: 1.42857143;
		color: #555;
	}

	code {
		background-color: #f6f8fa;
		font-size: $body-font-size;
		font-weight: 400;
		//font-family: $monospace;
		display: inline-block;
		padding: rem-calc(5px);
	}

	/*----------  Table  ----------*/
	table {
		width: auto;
		max-width: 100%;
		border-collapse: collapse;

		caption {
			font-family: $headings-font-family;
			text-align: left;
			padding-top: 10px;
			padding-bottom: 10px;
			caption-side: top;
		}

		tbody {
			tr:last-child {
				border-bottom: none;
			}
		}

		tr {
			border-bottom: 1px solid #e5e5e5;
		}

		th {
			font-weight: 700;
			text-align: left;
		}

		thead th {
			vertical-align: bottom;
		}

		th,
		td {
			vertical-align: top;
			padding: 9px;
			border: 1px solid;
			font-size: 14px;
		}
	}

	/*----------  Lists  ----------*/
	ul,
	ol,
	dl {
		padding: 0;
	}

	ul,
	ol {
		list-style-position: outside;
		margin-left: rem-calc(20px);
		;

		ul,
		ol {
			margin: 0;
			margin-top: rem-calc(5px);
			margin-left: rem-calc(40px);
		}

		ol {
			list-style-type: lower-alpha;
		}

		nav & {
			margin: 0;

			li {
				margin-bottom: 0;
				line-height: 1;
			}
		}
	}

	ul {
		list-style-type: disc;
	}

	ol {
		list-style-type: decimal;
	}

	li {
		line-height: $global-line-height;
		margin-bottom: $global-margin-bottom / 2;
	}

	dt {
		font-family: $headings-font-family;
		font-weight: 700;
		line-height: $global-line-height;
		margin: 0 0 ($global-margin-bottom / 2);
	}

	/*----------  Form  ----------*/
	form {
		ul {
			@include list-unstyled;
		}

		>ul {
			>li {
				clear: both;
				margin-bottom: $global-margin-bottom / 2;

				>label {
					margin-right: rem-calc(10px);
				}
			}
		}
	}

	fieldset {
		padding: 0;
		margin: 0;
		border: 0;
		min-width: 0;
	}

	legend {
		font-weight: 700;
		display: block;
		margin-bottom: $global-margin-bottom / 2;
		border: 0;
	}

	input[type="text"],
	input[type="tel"],
	input[type="url"],
	input[type="number"],
	input[type="password"],
	input[type="email"],
	input[type="range"],
	input[type="file"],
	input[type="date"],
	input[type="search"],
	textarea,
	select {
		@include input-text;
		max-width: 100%;
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type=number] {
		-moz-appearance: textfield;
	}


	input[type="submit"] {
		@include btn;
		@include btn-sm;
		@include btn-black;
	}

	input[type="search"] {
		-webkit-appearance: none;
	}

	input[type="date"] {
		line-height: rem-calc(32px);
	}

	textarea {
		line-height: $global-line-height;
		height: rem-calc(150px);
		resize: vertical;
	}

	input[type="checkbox"],
	input[type="radio"] {
		margin: 0;
		margin-top: rem-calc(4px);
		margin-right: rem-calc(5px);
		line-height: normal;
	}

	select[multiple],
	select[size] {
		height: auto;
	}

	input[type="file"] {
		display: block;
	}

	input[type="range"] {
		display: block;
		width: 100%;
	}

	input[type="file"]:focus,
	input[type="radio"]:focus,
	input[type="checkbox"]:focus {
		outline: thin dotted;
		outline: rem-calc(5px) auto -webkit-focus-ring-color;
		outline-offset: rem-calc(-2px);
	}

	/*----------  Miscellaneous  ----------*/
	img,
	iframe {
		max-width: 100%;
	}

	audio,
	canvas,
	iframe,
	img,
	svg,
	video {
		vertical-align: middle;
	}