.table-striped {
	tbody {
		tr:nth-child(odd) {
			background-color: #f9f9f9;
		}
	}
}

.table-bordered {
	th,
  td {
		border: 1px solid #e5e5e5;
	}
}

.table-hover {
  tbody tr {
    &:hover {
      background-color: #ddd;
    }
  }
}

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        // Prevent double border on horizontal scroll due to use of `display: block;`
        > .table-bordered {
          border: 0;
        }
      }
    }
  }
}