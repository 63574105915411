.bottom-panel {
  height: 25vh;
}

.bottom-panel--before-stream,
.bottom-panel--after-stream {
  @include display-flex;
  @include align-items(center);
  @include justify-content(center);
  height: 100%;

  button.bp-join {
    border: 0;
    outline: none;
    background: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: #404040;
  }
}

.product-slider-section {
  height: 100%;

  .slick-slide {
    height: 100%;
  }

  .product-slider {
    width: calc(100% - 70px);
    height: 100%;
  }

  .slider-controls {
    width: 70px;
  }

  .product-card .image-block {
    height: calc(100% - 58px);

    img {
      height: 100%;
      width: auto;
    }
  }

}