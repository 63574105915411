.data-address-form, .data-address {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.address-form {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  // justify-content: space-between;

  text-align: left;

input[type="text"], input[type="tel"], input[type="url"], input[type="number"], input[type="password"], input[type="email"], input[type="range"], input[type="file"], input[type="date"], input[type="search"], textarea, select, .custom-select-block {
  background: transparent;
}

  [data-aria-hidden="true"] {
    display: none;
  }

  [data-line-count="1"] {
    flex-basis: 100%;
  }
  [data-line-count="2"] {
    flex-basis: 49%;
  }
  [data-line-count="3"] {
    flex-basis: 32%;
  }
}
