// stylelint-disable declaration-no-important

//
// Text
//


// Alignment

.text-justify {
	text-align: justify !important;
}

.text-wrap {
	white-space: normal !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.text-truncate {
	@include text-truncate;
}

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.text#{$infix}-left {
			text-align: left !important;
		}

		.text#{$infix}-right {
			text-align: right !important;
		}

		.text#{$infix}-center {
			text-align: center !important;
		}
	}
}

// Transformation

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}


// Weight and italics

.font-weight-light {
	font-weight: $font-weight-light !important;
}

.font-weight-lighter {
	font-weight: $font-weight-lighter !important;
}

.font-weight-normal {
	font-weight: $font-weight-normal !important;
}

.font-weight-bold {
	font-weight: $font-weight-bold !important;
}

.font-weight-bolder {
	font-weight: $font-weight-bolder !important;
}

.font-italic {
	font-style: italic !important;
}

// Contextual colors

.text-white {
	color: $white !important;
}

.text-body {
	color: $body-color !important;
}

.text-black-50 {
	color: rgba($black, .5) !important;
}

.text-white-50 {
	color: rgba($white, .5) !important;
}

// Misc

.text-hide {
	@include text-hide($ignore-warning: true);
}

.text-decoration-none {
	text-decoration: none !important;
}

.text-break {
	word-break: break-word !important; // IE & < Edge 18
	overflow-wrap: break-word !important;
}

// Reset

.text-reset {
	color: inherit !important;
}


// Custom
.text-black {
	color: $black !important;
}

.text-primary {
	color: $primary !important;
}

.text-secondary {
	color: $secondary !important;
}

.ff-body {
	font-family: $body-font-family !important;
}

.ff-heading {
	font-family: $headings-font-family !important;
}

.underline {
	text-decoration: underline !important;
}

.lead,
.text-big {
	font-size: rem-calc(20px) !important;
}

.text-small {
	@extend small;
}

.lowercase {
	text-transform: lowercase !important;
}

.uppercase {
	text-transform: uppercase !important;
}

.capitalize {
	text-transform: capitalize !important;
}

.fw-light {
	font-weight: $font-weight-light !important;
}

.fw-lighter {
	font-weight: $font-weight-lighter !important;
}

.fw-normal {
	font-weight: $font-weight-normal !important;
}

.fw-bold {
	font-weight: $font-weight-bold !important;
}

.fw-bolder {
	font-weight: $font-weight-bolder !important;
}

.italic {
	font-style: italic !important;
}

.lh-1 {
	line-height: 1 !important;
}

.ls-0-5 {
	letter-spacing: rem-calc(.5px) !important;
}

.ls-1 {
	letter-spacing: rem-calc(1px) !important;
}

.ls-1-5 {
	letter-spacing: rem-calc(1.5px) !important;
}

.ls-2 {
	letter-spacing: rem-calc(2px) !important;
}

.ls-2-5 {
	letter-spacing: rem-calc(2.5px) !important;
}

.ls-3 {
	letter-spacing: rem-calc(3px) !important;
}