.ticker-banner-section {
  overflow: hidden;
  color: $primary;
  width: 100%;
  height: 100%;

  &:hover,
  &:focus {
    color: $primary;
  }

  .ticker {
    @include animation(ticker 30s linear infinite);
    white-space: nowrap;
    display: inline-block;
    padding-top: 3px;
  }

  p {
    margin: 0;
    padding: 0px 19px;
    font-size: 14px;
    letter-spacing: 1.5px;
  }
}