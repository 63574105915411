.banner-wrapper {
  height: 8vh;
  @include display-flex;
  @include align-items(center);
  @include justify-content(center);
}

.banner-ls-wrapper {
  width: 100%;
}

.hero-banner {
  background-repeat: no-repeat;
}

.hero-banner--article {
  min-height: 278px;
  background-position: center;
  background-size: cover;
}

.chat-control-block {
  position: relative;
  overflow: hidden;
}

.share-icon-wrapper {
  display: inline-flex;
  transform: translateX(49%);
  transition: .2s all ease;

  &.js-active {
    transform: translateX(0);
  }
}

.share-icons {
  position: relative;

  a {
    width: 19px;
    height: auto;
    display: inline-block;
    line-height: 2;
    min-width: 34px;
    text-align: center;

    svg {
      height: 16px;
    }
  }
}