/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($primary, white, 75%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, #888, 50%)) {
	::-webkit-scrollbar {
			width:  $size;
			height: $size;
	}

	::-webkit-scrollbar-thumb {
			background: $foreground-color;
			border-radius: 5px;
	}

	::-webkit-scrollbar-track {
			background: $background-color;
			border-radius: 5px;
	}

	// For Internet Explorer
	body {
		scrollbar-face-color: $foreground-color;
		scrollbar-track-color: $background-color;
	}
}