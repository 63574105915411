@mixin keyframes($animationName)
{
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName}  {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin animation-timing-function($propery){
  -webkit-animation-timing-function: $propery;
  -moz-animation-timing-function: $propery;
  -ms-animation-timing-function: $propery;
  -o-animation-timing-function: $propery;
  animation-timing-function: $propery;
}

@mixin animation-name($propery){
  -webkit-animation-name: $propery;
  -moz-animation-name: $propery;
  -ms-animation-name: $propery;
  -o-animation-name: $propery;
  animation-name: $propery;
}

@mixin transform-origin($property) {
  -webkit-transform-origin: center bottom $property;
  -moz-transform-origin: center bottom $property;
  -ms-transform-origin: center bottom $property;
  -o-transform-origin: center bottom $property;
  transform-origin: center bottom $property;
}

@mixin animation-duration( $property ){
  -webkit-animation-duration: $property;
  -moz-animation-duration: $property;
  -ms-animation-duration: $property;
  -o-animation-duration: $property;
  animation-duration: $property;
}
@mixin transition-duration($property) {
  -webkit-transition-duration: $property;
  -moz-transition-duration: $property;
  -ms-transition-duration: $property;
  -o-transition-duration: $property;
  transition-duration: $property;
}

@mixin transition-timing-function($property) {
  -webkit-transition-timing-function: $property;
  -moz-transition-timing-function: $property;
  -ms-transition-timing-function: $property;
  -o-transition-timing-function: $property;
  transition-timing-function: $property;
}

@mixin transition($property...) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -ms-transition: $property;
  -o-transition: $property;
  transition: $property;
}

@mixin transition-delay($property) {
  -webkit-transition-delay: $property;
  -moz-transition-delay: $property;
  -ms-transition-delay: $property;
  -o-transition-delay: $property;
  transition-delay: $property;
}

@mixin animation-delay($property) {
  -webkit-animation-delay: $property;
  -moz-animation-delay: $property;
  -ms-animation-delay: $property;
  -o-animation-delay: $property;
  animation-delay: $property;
}

@mixin animation($property) {
  -webkit-animation: $property;
  -moz-animation: $property;
  -ms-animation: $property;
  -o-animation: $property;
  animation: $property;
}