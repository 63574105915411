#shopify-section-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex-fixed;
}

.logo-image {
  display: block;
  max-width: 159px;
  width: 100%;
}

.profile-avatar {
  height: 19px;
  width: 18px;
}

.logo-loading {
  color: black;
  padding-top: 48vh;
  width: 100vw;
  height: 100vh;
  background: white;
  text-align: center;
  animation-duration: 1s;
  animation-fill-mode: both;
  position: fixed;
  z-index: 999999999;
}

.header-section {
  //position: relative;
  width: 100%;
  // top: 0;
  // left: 0;
  z-index: 1;

  .announcement {
    background: $secondary;
    padding: 15px 0;

    a {
      color: $black;

      &:hover,
      &:focus {
        color: $darkgray;
      }
    }
  }

  header {
    height: 72px;
    background-color: $black;
    //border-bottom: 1px solid $darkgray;
  }

  header.loading-header {
    background: $secondary;
  }

  .logo-block {
    width: 130px;

    img {
      width: 100%;
    }
  }

  .right-block {
    width: calc(100% - 130px);

    li {
      padding: 0 8px;
      // @include display-flex;
      // @include align-items(center);
    }
  }

  .js-menu-toggle {
    &:focus {
      outline: none;
    }

    .hamburger-box {
      position: relative;
      display: inline-block;
      width: 19px;
      height: 17px;
    }

    .hamburger-inner {
      @include transition-timing-function(cubic-bezier(.55, .055, .675, .19));
      @include transition-duration(75ms);
      top: 50%;
      display: block;
      margin-top: 0;

      &:before,
      &:after {
        display: block;
        content: "";
      }

      &:before {
        @include transition(top 75ms ease .12s, opacity 75ms ease);
        top: -8px;
      }

      &:after {
        bottom: -8px;
      }
    }

    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      position: absolute;
      width: 19px;
      height: 1px;
      @include transition-timing-function(ease);
      @include transition-duration(.15s);
      transition-property: transform;
      border-radius: 4px;
      background-color: $primary;
    }

    &.js-active {
      .hamburger-inner {
        @include transition-delay(.12s);
        @include transition-timing-function(cubic-bezier(.215, .61, .355, 1));
        @include transform(rotate(45deg));

        &:before {
          top: 0;
          @include transition(top 75ms ease, opacity 75ms ease .12s);
          opacity: 0;
        }

        &:after {
          bottom: 0;
          @include transition(bottom 75ms ease, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s);
          @include transform(rotate(-90deg));
        }
      }
    }
  }

  .header-nav-wrapper {
    @include transform(translateX(100%));
    @include transition(350ms transform cubic-bezier(0.465, 0.183, 0.153, 0.946));
    overflow-x: hidden;
    position: fixed;
    top: 45px;
    right: 0;
    bottom: 0;
    width: 100%;
    //max-width: 314px;
    background-color: $secondary;
    z-index: 99;

    @include media-breakpoint-up(sm) {
      max-width: 314px;
    }

    &.js-active {
      @include transform(translateX(0));
      // box-shadow: -5px 15px 15px 0 rgba(0, 0, 0, 0.25);
    }

    &.announcement-active {
      top: 94px;
    }
  }

  .header-nav {
    background-color: $secondary;
    width: 100%;
    max-width: 314px;
    padding: 70px 30px 30px;

    &.announcement-active {
      top: 94px;
    }

    ul.menu-list {
      li.menu-item {
        margin-bottom: 25px;

        &.current {
          >a {
            color: $primary;
          }
        }

        &.js-active {
          ul.sub-menu {
            @include transform(translateX(0));
            box-shadow: -5px 15px 15px 0 rgba(0, 0, 0, 0.25);
          }
        }
      }

      a {
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        color: $primary;
        line-height: 1.3;
        position: relative;
        display: inline-block;

        &:hover,
        &:focus {
          color: $primary;
        }

        span {
          left: calc(100% + 15px);
          position: absolute;
          top: 0;
          bottom: 0;
          width: 20px;
          height: 20px;
          display: block;
          text-align: center;
        }
      }
    }

    ul.sub-menu {
      background-color: $white;
      width: 100%;
      max-width: 314px;
      padding: 70px 30px;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      overflow-x: hidden;
      @include transform(translateX(100%));
      @include transition(350ms transform cubic-bezier(0.465, 0.183, 0.153, 0.946));

      .back {
        position: absolute;
        top: 20px;
      }

      &.current>a {
        color: $darkgray;
      }

      &.js-active {
        @include transform(translateX(0));
        box-shadow: -5px 15px 15px 0 rgba(0, 0, 0, 0.25);
      }
    }

    .nav-botttom-list {
      li {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }

        a,
        small {
          font-size: 10px;
          font-weight: 400;
          line-height: 1.2;
          color: #b2b2b2;

          &:hover,
          &:focus {
            color: $darkgray;
          }
        }
      }
    }

  }

  .cart-icon {
    position: relative;

    svg {
      width: 29px;
      height: auto;
      margin-top: -1px;
    }

    .cart-items {
      position: absolute;
      width: 14px;
      height: 14px;
      background: $secondary;
      font-size: 9px;
      text-align: center;
      color: #000;
      border-radius: 50%;
      line-height: 16px;
      top: -5px;
      right: -4px;
    }
  }

  .js-customer-button {
    svg {
      width: 23px;
      height: auto;
      top: -1px;
      position: relative;
    }
  }

  // .signup-form {
  //   .btn {
  //     width: 100%;
  //     span {
  //       display: inline-flex;
  //       padding-left: 10px;
  //     }
  //   }
  //   .signup-form-close {
  //     width: auto;
  //     position: absolute;
  //     right: 30px;
  //     top: 5px;
  //   }
  // }

  .customer-link-list {
    position: fixed;
    right: 0;
    width: 220px;
    height: 50px;
    //box-shadow: -5px 0 15px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    @include transform(translateY(10px));
    @include transition(0.2s);
    opacity: 0;
    pointer-events: none;

    &.js-active {
      opacity: 1;
      pointer-events: all;
      @include transform(translateY(0));
    }

    li {
      margin-bottom: 0;
      line-height: 1;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;

      &:nth-child(2) {
        padding: 0 30px;
      }
    }

    a {
      color: $black;

      &:hover,
      &:focus {
        color: $darkgray;
      }
    }

  }

  .social-list {
    margin-left: -5px;
    margin-right: -5px;

    li {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: 0 5px;
    }

    svg {
      width: 100%;
      height: 16px;
    }
  }

  @include media-breakpoint-up(md) {
    .customer-link-list {
      width: 314px;
      height: 79px;
    }

    // .signup-form {
    //   max-width: 628px;
    //   .signup-form-close {
    //     right: 50px;
    //     top: 15px;
    //   }
    // }
  }
}