//@import "rem"; // always first for input to work
@import "background";
@import "input";
@import "buttons";
@import "icons";
@import "gradients";
@import "images";
@import "keyframes";
@import "animations";
@import "scrollbars";
@import "media-queries";
@import "fluid-typography";
@import "flexbox";

// Bootstrap
@import "breakpoints";
@import "grid";
@import "grid-framework";
@import "screenreaders";
@import "text-truncate";
@import "text-hide";

@mixin pagination-links {
	background: #fff;
	color: #666;
	font-size: rem-calc(12px);
	line-height: 1;
	text-decoration: none;
	text-align: center;
	display: block;
	min-width: rem-calc(35px);
	padding: rem-calc(10px);
	box-shadow: 0 0 rem-calc(1px) rgba(0,0,0,.3);
}

@mixin clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin list-unstyled {
	margin-left: 0;
	> li {
		list-style: none;
	}
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}