.my-account-section {
  .order-link {
    color: $darkgray;

    &:hover,
    &:focus {
      color: $black;
    }
  }

  table {
    margin: 0 auto;
  }
}

.my-account-section,
.address-section {
  font-size: 14px;
}