.hero-section-wrapper {
  min-height: 92vh;
}

.hero-section {
  // padding-top: 95px;
  position: relative;
  //min-height: calc(100vh - 64px);
  min-height: 92vh;
  overflow: hidden;

  .container-fluid {
    width: 100%;
    position: relative;
    z-index: 1;
  }

  h1 {
    max-width: 443px;
    margin: 0 auto;
    font-size: 16px;
  }

  video {
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    max-width: 100%;

    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    overflow: hidden;
  }

  // &:after {
  //   content: '';
  //   background-color: #000000;
  //   opacity: 0.65;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  // }

  // @include media-breakpoint-up(md) {
  //   min-height: calc(67vh - 84px);
  // }

  @include media-breakpoint-up(md) {
    min-height: 92vh;
  }
}