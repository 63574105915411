/*----------  Required  ----------*/
/* suppress focus ring on form controls for mouse and touch users, using what-input js */
[data-whatintent='mouse'] *:focus, [data-whatintent='touch'] *:focus {
  outline: none;
}

h1 + h1,
h1 + h2,
h1 + h3,
h1 + h4,
h1 + h5,
h1 + h6,
h2 + h1,
h2 + h2,
h2 + h3,
h2 + h4,
h2 + h5,
h2 + h6,
h3 + h1,
h3 + h2,
h3 + h3,
h3 + h4,
h3 + h5,
h3 + h6,
h4 + h1,
h4 + h2,
h4 + h3,
h4 + h4,
h4 + h5,
h4 + h6,
h5 + h1,
h5 + h2,
h5 + h3,
h5 + h4,
h5 + h5,
h5 + h6,
h6 + h1,
h6 + h2,
h6 + h3,
h6 + h4,
h6 + h5,
h6 + h6 {
	margin-top: 0;
}

.custom-select-block {
	@include custom-select-block;
}

.hr {
	@extend hr;
	margin-top: 20px;
	margin-bottom: 20px;
}

p:empty { // added by wp-editor
	display: none;
}

.btn {
	@include btn;
}

/*----------  AOS  ----------*/
// html.js.mutationobserver.cssanimations:not(.js-mobile) [data-aos] {
//   visibility: hidden;
//   &.animated {
//   	visibility: visible;
//   }
// }

// html.js-mobile [data-aos],
// html.no-mutationobserver [data-aos],
// html.no-js [data-aos],
// html.no-cssanimations [data-aos] {
//   @include animation( none !important);
// }


/*----------  Scroll Reveal  ----------*/
// html.js.mutationobserver.cssanimations:not(.js-mobile) [data-sreveal],
// html.js.mutationobserver.cssanimations:not(.js-mobile) .sreveal {
// 	// @include animation-name( none );
// 	visibility: hidden !important;
// 	&.animated {
// 		visibility: visible !important;
// 	}
// }


/*----------  Swiper Arrow Navigation  ----------*/
// .swiper-arrow-navigation {
// 	.btn {
// 		color: $primary;
// 		position: absolute;
// 		@include transition( .2s ease-in-out );
// 		&.swiper-button-disabled {
// 			opacity: .4;
// 			pointer-events: none;
// 		}
// 		&:after {
// 			@include icon-font-properties;
// 			content: '';
// 			display: block;
// 			font-size: rem-calc(36px);
// 		}
// 		&:hover {
// 			&:after {
// 				//@include animation( leftRightBounce 1s infinite );
// 			}
// 		}
// 	}
// 	.btn-next {
// 		right: rem-calc(15px);
// 		&:before {
// 			left: 0;
// 			@include translateX( -100% );
// 		}
// 		&:after {
// 			content: $i-arrow-right;
// 		}
// 	}
// 	.btn-prev {
// 		left: rem-calc(15px);
// 		&:before {
// 			right: 0;
// 			@include translateX( 100% );
// 		}
// 		&:after {
// 			content: $i-arrow-left;
// 		}
// 	}
// 	@include media-breakpoint-up( sm ) {
// 		.btn {
// 			&:after {
// 				font-size: rem-calc(60px);
// 			}
// 		}
// 	}
// }

/*----------  Slick Dots  ----------*/
// .slick-dots {
// 	@include list-unstyled;
// 	li {
// 		display: inline-block;
// 		margin: 0 rem-calc(10px) 0 0;
// 		position: relative;
// 		&.slick-active {
// 			button {
// 				background-color: #fff;
// 			}
// 		}
// 	}
// 	button {
// 		@include btn;
// 		@include hide-text;
// 		background-color: rgba(255,255,255,.5);
// 		width: rem-calc(10px);
// 		height: rem-calc(10px);
// 		border-radius: 50%;
// 	}
// }