.App {
  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    @include transition(0.2s);
  }
  &.js-active {
    .overlay {
      opacity: 1;
      pointer-events: all;
    }
  }
}