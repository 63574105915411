//
// Screenreaders
//

.sr-only, .screen-reader-text {
  @include sr-only();
}

.sr-only-focusable {
  @include sr-only-focusable();
}


// Custom
.screen-reader-text {
	@include sr-only();
}