.i-font {
  @include icon-font-properties;
}

.i-right-arrow:before {
  content: "\e90d";
}
.i-search:before {
  content: "\e906";
}
.i-mail:before {
  content: "\e908";
}
.i-phone:before {
  content: "\e909";
}
.i-share:before {
  content: "\e90a";
}
.i-instagram:before {
  content: "\e905";
}
.i-down-squeeze-arrow:before {
  content: "\e901";
}
.i-left-squeeze--arrow:before {
  content: "\e902";
}
.i-right-squeeze-arrow:before {
  content: "\e903";
}
.i-up-squeeze--arrow:before {
  content: "\e904";
}
.i-calendar:before {
  content: "\e60d";
}
.i-chat:before {
  content: "\e60e";
}
.i-rotate-cw:before {
  content: "\e90c";
}
.i-play_arrow:before {
  content: "\e900";
}
.i-favorite-outline:before {
  content: "\e600";
}
.i-home:before {
  content: "\e60f";
}
.i-launch:before {
  content: "\e611";
}
.i-pause-circle-outline:before {
  content: "\e601";
}
.i-play-circle-outline:before {
  content: "\e602";
}
.i-comment:before {
  content: "\e603";
}
.i-email:before {
  content: "\e613";
}
.i-location-on:before {
  content: "\e604";
}
.i-send:before {
  content: "\e614";
}
.i-check:before {
  content: "\e61d";
}
.i-close:before {
  content: "\e62e";
}
.i-menu:before {
  content: "\e62f";
}
.i-check-box:before {
  content: "\e606";
}
.i-check-box-outline-blank:before {
  content: "\e607";
}
.i-radio-button-off:before {
  content: "\e608";
}
.i-radio-button-on:before {
  content: "\e609";
}
.i-square-check:before {
  content: "\e046";
}
.i-square-cross:before {
  content: "\e047";
}
.i-arrow-left:before {
  content: "\e094";
}
.i-arrow-right:before {
  content: "\e095";
}
.i-arrow-up:before {
  content: "\e096";
}
.i-arrow-down:before {
  content: "\e097";
}
.i-email1:before {
  content: "\e61e";
}
.i-file_downloadget_app:before {
  content: "\e90f";
}
.i-upload:before {
  content: "\e90b";
}
.i-chevron-thin-down:before {
  content: "\e615";
}
.i-chevron-thin-left:before {
  content: "\e616";
}
.i-chevron-thin-right:before {
  content: "\e617";
}
.i-chevron-thin-up:before {
  content: "\e618";
}
.i-chevron-with-circle-down:before {
  content: "\e619";
}
.i-chevron-with-circle-left:before {
  content: "\e61a";
}
.i-chevron-with-circle-right:before {
  content: "\e61b";
}
.i-chevron-with-circle-up:before {
  content: "\e61c";
}
.i-linkedin:before {
  content: "\e62b";
}
.i-twitter:before {
  content: "\e631";
}
.i-facebook:before {
  content: "\e907";
}
.i-quotes-left:before {
  content: "\e977";
}
.i-reddit:before {
  content: "\eac6";
}
.i-reddit-alien:before {
  content: "\e90e";
}