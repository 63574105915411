.main-content {
  padding-bottom: 0px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 0px;
  }

  >section {
    &:first-child {
      padding-top: 80px;

      @include media-breakpoint-up(lg) {
        padding-top: 0px;
      }
    }
  }
}

// .container-fluid {
//   max-width: 1600px;
// }