.collab-list {
  .image-block {
    position: relative;
    .hover-item {
      background-color: rgba(233, 227, 212, 0.9);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      @include transition(0.2s);
      @include display-flex;
      @include align-items(center);
      @include justify-content(center);
    }
  }
  .collab-item {
    display: block;
    &:hover, &:focus {
      .image-block {
        .hover-item {
          opacity: 1;
        }
      }
    }
  }

	h5, .title {
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
  }

  img {
    width: 100%;
  }

  .detail-block {
    min-height: 60px;
  }
  .date-block {
    position: absolute;
    left: 0;
  }
}