.btn-black {
	@include btn-black;
}

.btn-primary {
	@include btn-primary;
}

.btn-secondary {
	@include btn-secondary;
}

.btn-black-border {
	@include btn-black-border;
}

.btn-white {
	@include btn-white;
}

.btn-black {
	@include btn-black;
}

.btn-darkgray {
	@include btn-darkgray;
}

.btn-checkout {
	@include btn-checkout;
}

.btn-xs {
	@include btn-xs;
}

.btn-sm {
	@include btn-sm;
}

.btn-md {
	@include btn-md;
}

.btn-lg {
	@include btn-lg;
}

.btn-xlg {
	@include btn-xlg;
}

.btn-input-clone {
	@include btn-input-clone(transparent);
}

.btn-disabled {
	@include btn-disabled;
}

.btn-block {
	display: block;
}