// Width and height

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }

  }
}

.mw-100 { max-width: 100% !important; }
.mh-100 { max-height: 100% !important; }

// Viewport additional helpers
.min-vw-100 { min-width: 100vw !important; }
.min-vh-100 { min-height: 100vh !important; }

.vw-100 { width: 100vw !important; }
.vh-100 { height: 100vh !important; }


// Custom
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .maxw#{$infix}-100 {
      max-width: 100%;
    }
    .minw#{$infix}-100 {
			min-width: 100%;
		}
		.maxw#{$infix}-none {
		  max-width: none;
		}

  }
}