/* Skeleton */
// Project specific
$red : #ff2500;
// Required colors
$white : #fff;
$black : #000000;
$camel : #e9e3d4;
$darkgray : #858d95;


$primary : $white;
$secondary : $black;
$body-color : $black;

// Colors
$colors: (red : $red,
	white : $white,
	black : $black,
	primary : $primary,
	secondary : $secondary,
	body : $body-color,
);


// Links
$base-link-color : $camel;
$base-link-hover-color : $darkgray;
$base-link-decoration : none;
$base-link-hover-decoration : none;


// Font family
$suisse_intlregular: 'SuisseIntl-Regular',
sans-serif;
$helvetica_compressed: 'Helvetica-UltraCompressed',
sans-serif;
$helvetica_neue: 'HelveticaNeueLTStd-BlkEx',
sans-serif;


// Body
$body-bg-color : #fff;
$body-font-size : 16px;
$body-font-weight : 400;
$body-font-family : $suisse_intlregular;


// Spacing
$global-margin-bottom : 30px; // p, table, ul, ol, dl, dd, pre, blockquote, address
$global-line-height : 1.7; // p, pre, blockquote, address, dt, textarea


// Headings
$headings-color : inherit;
$headings-font-family : inherit;
$headings-margin-top : $global-margin-bottom*2;
$headings-margin-bottom : 20px;

// Input
// variables are set in input mixin file because it requires rem-values


// Icons,
$i-menu : '\e62f';
$i-search : '\e612';
$i-cross : '\e62e';
$i-calendar : '\e60d';
$i-radio : '\e608';
$i-radio-checked : '\e609';
$i-checkbox : '\e607';
$i-checkbox-checked : '\e046';
$i-mail : '\e613';
$i-play : '\e900';
$i-play-circle : '\e602';
$i-tick : '\e61d';
// Arrows
$i-chevron-down : '\e615';
$i-chevron-up : '\e617';
$i-chevron-left : '\e616';
$i-chevron-right : '\e617';
$i-arrow-down : '\e097';
$i-arrow-up : '\e096';
$i-arrow-left : '\e094';
$i-arrow-right : '\e095';
$i-dropdown : $i-chevron-down;
$i-go-top : '\e61c';
// Socials
$i-facebook : '\e907';
$i-twitter : '\e631';
$i-instagram : '\e905';
$i-gplus : '\e627';
$i-youtube : '\e634';
$i-pinterest : '\e62d';
$i-linkedin : '\e62b';
// Add project specific here


// Text selection
$text-selection-bg-color : #F7EA6F;
$text-selection-color : #2c2c2c;


// Font weights
$font-weight-lighter : lighter;
$font-weight-light : 300;
$font-weight-normal : 400;
$font-weight-bold : 700;
$font-weight-bolder : bolder;


// This variable affects the `.pt-*` and `.mb-*` classes etc.
$spacers : (0 : 0,
	0-#{5} : 5px,
	1 : 10px,
	1-#{5} : 15px,
	2 : 20px,
	2-#{5} : 25px,
	3 : 30px,
	3-#{5} : 35px,
	4 : 40px,
	4-#{5} : 45px,
	5 : 50px,
	5-#{5} : 55px,
	6 : 60px,
	6-#{5} : 65px,
	7 : 70px,
	7-#{5} : 75px,
	8 : 80px,
	8-#{5} : 85px,
	9 : 90px,
	9-#{5} : 95px,
	10 : 100px,
	11 : 110px,
	12 : 120px,
	13 : 130px,
	14 : 140px,
	15 : 150px,
	16 : 160px,
	17 : 170px,
	18 : 180px,
	19 : 190px,
	20 : 200px,
);


// Breakpoints - Min width
$xs : 0; // do not change
$min-375 : 375px;
$min-414 : 414px;
$sm : 576px;
$md : 768px;
$lg : 992px;
$xl : 1200px;
$xxl : 1400px;
$xxxl : 1600px;
$two-k : 1920px;
$three-k : 2560px;
$four-k : 3840px;
$mac-13 : ($md) (max-height 800px);



// Changes depending on the project design, used on html element
$mobile: $min-375; // mobile canvas size, mostly remains same
$desktop: $two-k; // desktop canvas size
$scaling: $two-k; // scaling starts from here, do not change if not sure, it cannot be smaller than $desktop


// Breakpoints - Map
$grid-breakpoints : (xs : $xs,
	sm : $sm,
	md : $md,
	lg : $lg,
	xl : $xl,
	xxl : $xxl,
	xxxl : $xxxl,
	// this and $container-max-widths "map size" should match
	// add more if required
);

// Grid columns
// Set the number of columns and specify the width of the gutters.
$enable-grid-classes : true;
$grid-columns : 12;
$grid-gutter-width : 30px; // gets divided by half per column for columns
$container-padding : 5vw; // each x-side


// Grid containers
// Define the maximum width of `.container` for different screen sizes.
// Changed the value to rem to scale on higher size screens
$container-max-widths: (sm : 95vw,
	md : 95vw,
	lg : 93vw,
	xl : 93vw,
	xxl : 93vw,
	xxxl : 155rem, // max-width value needs to be less than the (breakpoint value(xxxl) - $container-padding)
	// this and $grid-breakpoints "map size" should match
);

$container-sm-max-width : map-get($container-max-widths, 'sm');
$container-md-max-width : map-get($container-max-widths, 'md');
$container-lg-max-width : map-get($container-max-widths, 'lg');
$container-xl-max-width : map-get($container-max-widths, 'xl');
$container-xxl-max-width : map-get($container-max-widths, 'xxl');
$container-xxxl-max-width : map-get($container-max-widths, 'xxxl');


// Embed aspect ratio
$embed-responsive-aspect-ratios: ((21 9),
	(16 9),
	(4 3),
	(1 1),
);


// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (20 : 20%,
	25 : 25%,
	30 : 30%,
	35 : 35%,
	40 : 40%,
	45 : 45%,
	50 : 50%,
	55 : 55%,
	60 : 60%,
	65 : 65%,
	70 : 70%,
	75 : 75%,
	80 : 80%,
	85 : 85%,
	90 : 90%,
	95 : 95%,
	100 : 100%,
	auto : auto);


// Breakpoints - Orientation
$portrait: orientation portrait;
$landscape: orientation landscape;


// Utilities
$displays : none,
inline,
inline-block,
block,
table,
table-row,
table-cell,
flex,
inline-flex;
$overflows : auto,
hidden;
$positions : static,
relative,
absolute,
fixed,
sticky;


// Printing
$enable-print-styles : true;
$print-page-size : a3;
$print-body-min-width : map-get($grid-breakpoints, "lg");


// Z-index
$zindex-dropdown : 1000;
$zindex-sticky : 1020;
$zindex-fixed : 1030;
$zindex-modal-backdrop : 1040;
$zindex-modal : 1050;
$zindex-popover : 1060;
$zindex-tooltip : 1070;






// //Slate
// // Text colors
// $color-body-text: var(--color-body-text);

// // Background colors
// $color-body: var(--color-main-background);

// // Placeholders
// $placeholder-color-foreground: #161d25;
// $placeholder-color-background: #f9fafb;

// // Other colors
// $color-accent: var(--color-accent);
// $color-border: var(--color-border);

// // Helper colors for form error states
// $color-disabled: #000000;
// $color-disabled-border: #000000;
// $color-error: #ff6d6d;
// $color-error-bg: #ffffff;
// $color-success: #000000;
// $color-success-bg: #000000;

// // Typography
// $font-heading: var(--font-heading);
// $font-body: var(--font-body);
// $font-body-weight: var(--font-body-weight);
// $font-body-style: var(--font-body-style);
// $font-body-bold-weight: var(--font-body-bold-weight);

// // Transitions
// $transition-duration: 250ms;
// $transition-timing: ease-in-out;

// // Grid
// $grid-medium: 46.85em;
// $grid-large: 61.85em;
// $grid-widescreen: 87.5em;
// $grid-max-width: 73.75em;
// $grid-gutter: 30px;

// // Breakpoints used with the media-query mixin
// $small: 'small';
// $medium: 'medium';
// $medium-down: 'medium-down';
// $medium-up: 'medium-up';
// $large: 'large';
// $large-down: 'large-down';
// $large-up: 'large-up';
// $widescreen: 'widescreen';

// $breakpoints: (
//   $small '(max-width: #{$grid-medium - 1})',
//   $medium '(min-width: #{$grid-medium}) and (max-width: #{$grid-large - 1})',
//   $medium-down '(max-width: #{$grid-large - 1})',
//   $medium-up '(min-width: #{$grid-medium})',
//   $large '(min-width: #{$grid-large}) and (max-width: #{$grid-widescreen - 1})',
//   $large-down '(max-width: #{$grid-widescreen - 1})',
//   $large-up '(min-width: #{$grid-large})',
//   $widescreen '(min-width: #{$grid-widescreen})'
// );