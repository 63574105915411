// Custom
.alert {
	background-color: #ddd;
	border: rem-calc(1px) solid darken(#ddd, 47%);
	padding: rem-calc( 15px );
}

.alert-info {
	background-color: #ffffd9;
	border: rem-calc(1px) solid darken(#ffffd9, 47%);
}

.alert-error {
	background-color: #ffd9d9;
	border: rem-calc(1px) solid darken(#ffd9d9, 10%);
}

.alert-success {
	background-color: #dbffd9;
	border: rem-calc(1px) solid darken(#dbffd9, 20%);
}