.livestream-section {
  min-height: 50vh;

  @include media-breakpoint-up(md) {
    height: 100%;
    overflow: hidden;
    background: black;
    min-height: 50vh;
    display: flex;
    align-items: flex-end;
  }

  .stream-block {
    position: static;
    width: 100%;
    height: auto;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 45px;
      left: 0;
      width: 100%;
      height: calc(100% - 45px);
    }

    iframe {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
      min-height: 300px;

      @include media-breakpoint-up(md) {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }

  .bottom-block {
    position: relative;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    height: 67vh;
  }

  @include media-breakpoint-up(lg) {
    .chat-control-block {
      width: 262px;
    }
  }
}