.list-social-icons {
	@include display-flex;
	@include justify-content( center );
	margin: 0;
	list-style: none;
	li {
		margin: 0 rem-calc(10px) 0 0;
		&:last-child {
			margin-right: 0;
		}
		a {
			@include text-hide;
			color: rgba(255,255,255,.4);
			text-align: center;
			text-decoration: none;
			display: block;
			height: 100%;
			position: relative;
			&:before {
				@include icon-font-properties;
				font-size: rem-calc(24px);
				display: block; // cross browser fix
			}
			&:hover {
				color: #fff;
				text-decoration: none;
			}
		}
	}
	.i-facebook {
		a {
			&:before {
				content: $i-facebook;
			}
		}
	}
	.i-twitter {
		a {
			&:before {
				content: $i-twitter;
			}
		}
	}
	.i-instagram {
		a {
			&:before {
				content: $i-instagram;
			}
		}
	}
}


// .list-social-icons {
// 	margin: 0;
// 	list-style: none;
// 	li {
// 		float: left;
// 		margin: 0 rem-calc(7px) 0 0;
// 		&:last-child {
// 			margin-right: 0;
// 		}
// 		a {
// 			@include hide-text;
// 			text-align: center;
// 			text-decoration: none;
// 			display: block;
// 			height: 100%;
// 			position: relative;
// 			&:before {
// 				@include icon-font-properties;
// 				color: #333;
// 				font-size: rem-calc(48px);
// 				display: block; // cross browser fix
// 			}
// 			&:hover {
// 				text-decoration: none;
// 				.tooltip {
// 					visibility: visible;
// 					opacity: 1;
// 					@include transform( translateX(-50%) translateY(7px) scale(1) ); //transpateY same as tooltip border
// 					z-index: 10;
// 				}
// 			}
// 			.tooltip {
// 				visibility: hidden;
// 				opacity: 0;
// 				background-color: #333;
// 				color: #fff;
// 				font: rem-calc(20px) $body-font-family; //must use font shorthand to overwrite above
// 				text-align: center;
// 				padding: rem-calc(10px) rem-calc(20px);
// 				position: absolute;
// 				top: 100%;
// 				left: 50%;
// 				@include transform( translateX(-50%) translateY(20px) scale(1.2) );
// 				border-radius: rem-calc(5px);
// 				@include transition( 250ms ease-in-out );
// 				&:before {
// 					content: '';
// 					border: rem-calc(7px) solid transparent;
// 					border-bottom-color: #333;
// 					position: absolute;
// 					bottom: 100%;
// 					left: 50%;
// 					@include translateX(-50%);
// 				}
// 			}
// 		}
// 	}
// 	.i-facebook {
// 		a {
// 			&:before {
// 				content: $i-facebook;
// 			}
// 		}
// 	}
// 	.i-twitter {
// 		a {
// 			&:before {
// 				content: $i-twitter;
// 			}
// 		}
// 	}
// 	.i-instagram {
// 		a {
// 			&:before {
// 				content: $i-instagram;
// 			}
// 		}
// 	}
// 	.i-linkedin {
// 		a {
// 			&:before {
// 				content: $i-linkedin;
// 			}
// 		}
// 	}
// 	.i-gplus {
// 		a {
// 			&:before {
// 				content: $i-gplus;
// 			}
// 		}
// 	}
// 	.i-pinterest {
// 		a {
// 			&:before {
// 				content: $i-pinterest;
// 			}
// 		}
// 	}
// 	@include media-breakpoint-up( sm ) {
// 		li {
// 			a {
// 				.tooltip {
// 					display: block;
// 				}
// 			}
// 		}
// 	}
// }