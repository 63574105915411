.product-card {
  padding: 15px 0;
  width: 100%;
  height: 25vh;
  cursor: pointer;
  min-height: 145px;

  &:hover,
  &:focus {
    background: rgba(233, 227, 212, 0.51);
    outline: none;
  }

  .image-block {
    height: calc(100% - 58px);

    img {
      height: 100%;
      width: auto;
    }
  }

  .detail-block {
    padding-top: 20px;
    //margin-left: -30px;
    // position: absolute;
    // bottom: 11px;
  }

  .product-name {
    padding-bottom: 3px;
  }
}