@mixin btn {
	background: transparent;
	font-weight: 400;
	line-height: $input-line-height;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	display: inline-block;
	border: 0;
	padding: 0;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-clip: padding-box;
	transition-property: background, color, border-color;
	transition-duration: .2s;

	&:hover {
		transition-duration: .1s;
		text-decoration: none;
	}

	&:focus {
		outline: none;
	}
}

@mixin btn-red {
	background: $primary;
	color: #fff;

	&:hover,
	&:focus {
		background: $primary;
		color: #fff;
	}
}

@mixin btn-black {
	background: $black;
	color: #fff;

	&:hover,
	&:focus {
		background: $black;
		color: #fff;
	}
}

@mixin btn-black-border {
	background: $white;
	border: 1px solid $black;
	color: $black;

	&:hover,
	&:focus {
		background: $white;
		border: 1px solid $black;
		color: $black;
	}
}

@mixin btn-black {
	background: $primary;
	border: 1px solid $camel;
	color: $black;

	&:hover,
	&:focus {
		background: $primary;
		border: 1px solid $camel;
		color: $black;
	}
}

@mixin btn-checkout {
	background: $white;
	border: 1px solid $black;
	color: $black;

	&:hover,
	&:focus {
		background: $white;
		border: 1px solid $black;
		color: $black;
		// svg {
		// 	path {
		// 		stroke: #fff;
		// 	}
		// }
	}
}

@mixin btn-white {
	background: $white;
	border: 1px solid $black;
	color: $black;

	&:hover,
	&:focus {
		background: $white;
		border: 1px solid $black;
		color: $black;
	}
}

@mixin btn-darkgray {
	background: $darkgray;
	border: 1px solid $darkgray;
	color: $black;
	font-weight: bold;

	&:hover,
	&:focus {
		background: $darkgray;
		border: 1px solid $darkgray;
		color: $black;
	}
}

@mixin btn-primary {
	@include btn-red;
}

@mixin btn-secondary {
	@include btn-black;
}

@mixin btn-xs {
	padding: 7px 10px 5px;
}

@mixin btn-sm {
	padding: 15px 20px 12px;
}

@mixin btn-md {
	padding: 17px 20px 15px;
}

@mixin btn-lg {
	padding: 22px 30px 20px;
}

@mixin btn-xlg {
	padding: 27px 40px 25px;
}

@mixin btn-input-clone($bg-color) {
	background-color: $bg-color;
	font-size: 1rem;
	line-height: $input-line-height;
	text-transform: uppercase;
	padding-top: $input-px;
	padding-bottom: $input-px;
	border: rem-calc(1px) solid $bg-color;

	@include media-breakpoint-up(md) {
		padding-top: $input-px-md;
		padding-bottom: $input-px-md;
	}
}

@mixin btn-disabled {
	cursor: default;
	pointer-events: none;
	opacity: .4;
}