// Custom
.slick-slide {
	display: none;
	margin-bottom: 0;
	&:focus {
		outline: none;
	}
	a {
		&:focus {
			outline: none;
		}
	}
}

.slick-cloned { // custom css it will fix the inifinite loop last slider flickering issue
	@include transform( translate3d(0, 0, 0) );
}

.slick-slider {
	cursor: move; /* fallback if grab cursor is unsupported */
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
}
// End Custom

// Plugin
// All px changed to rem
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}
.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}
.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}
.slick-slide {
	float: left;
	height: 100%;
	min-height: rem-calc( 1px );
	[dir="rtl"] & {
		float: right;
	}
	img {
		display: block;
	}
	&.slick-loading img {
		display: none;
	}

	display: none;

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: rem-calc( 1px ) solid transparent;
	}
}
.slick-arrow.slick-hidden {
	display: none;
}


.slick-dots {
	display: -moz-flex !important;
	display: -ms-flexbox !important;
	display: flex !important;
	@include justify-content(center);
	@include list-unstyled;
	padding-top: 15px;
	padding-bottom: 5px;
	li {
		margin-bottom: 0;
		padding: 0px 10px;
		opacity: 0.5;
    width: 5px;
    height: 5px;
		&.slick-active {
			opacity: 1;
		}
	}
	button {
		background: $black;
    border: none;
    color: transparent;
    padding: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
	}
}