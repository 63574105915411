.page-template {
  .page-title {
		font-size: 16px;
		font-weight: 600;
  }
  .rte {
    font-size: 11px;
  }

	p,
	table,
	ul,
	ol,
	dl,
	dd,
	pre,
	blockquote,
	address {
		margin: 0 0 15px;
	}
}