// Custom

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		// Spacing
		@each $prop, $abbrev in (margin: m, padding: p) {
			@each $size, $length in $spacers {
				@if $size == 0 { // only 0

					// First child
					.first\:#{$abbrev}#{$infix}-#{$size}:first-child { #{$prop}: $length !important; }
					.first\:#{$abbrev}t#{$infix}-#{$size}:first-child,
					.first\:#{$abbrev}y#{$infix}-#{$size}:first-child {
						#{$prop}-top: $length !important;
					}
					.first\:#{$abbrev}r#{$infix}-#{$size}:first-child,
					.first\:#{$abbrev}x#{$infix}-#{$size}:first-child {
						#{$prop}-right: $length !important;
					}
					.first\:#{$abbrev}b#{$infix}-#{$size}:first-child,
					.first\:#{$abbrev}y#{$infix}-#{$size}:first-child {
						#{$prop}-bottom: $length !important;
					}
					.first\:#{$abbrev}l#{$infix}-#{$size}:first-child,
					.first\:#{$abbrev}x#{$infix}-#{$size}:first-child {
						#{$prop}-left: $length !important;
					}

					// Last child
					.last\:#{$abbrev}#{$infix}-#{$size}:last-child { #{$prop}: $length !important; }
					.last\:#{$abbrev}t#{$infix}-#{$size}:last-child,
					.last\:#{$abbrev}y#{$infix}-#{$size}:last-child {
						#{$prop}-top: $length !important;
					}
					.last\:#{$abbrev}r#{$infix}-#{$size}:last-child,
					.last\:#{$abbrev}x#{$infix}-#{$size}:last-child {
						#{$prop}-right: $length !important;
					}
					.last\:#{$abbrev}b#{$infix}-#{$size}:last-child,
					.last\:#{$abbrev}y#{$infix}-#{$size}:last-child {
						#{$prop}-bottom: $length !important;
					}
					.last\:#{$abbrev}l#{$infix}-#{$size}:last-child,
					.last\:#{$abbrev}x#{$infix}-#{$size}:last-child {
						#{$prop}-left: $length !important;
					}

				}
			}
		}

	}
}

// Hover color
.hover\:text-body:hover {
	color: $body-color !important;
}
.hover\:text-white:hover {
	color: $white !important;
}
.hover\:text-black:hover {
	color: $black !important;
}
.hover\:text-white-50:hover {
	color: rgba($white, .5) !important;
}
.hover\:text-black-50:hover {
	color: rgba($black, .5) !important;
}
.hover\:text-black-50:hover {
	color: rgba($black, .5) !important;
}

// Focus color
.focus\:text-body:focus {
	color: $body-color !important;
}
.focus\:text-white:focus {
	color: $white !important;
}
.focus\:text-black:focus {
	color: $black !important;
}
.focus\:text-white-50:focus {
	color: rgba($white, .5) !important;
}
.focus\:text-black-50:focus {
	color: rgba($black, .5) !important;
}
.focus\:text-black-50:focus {
	color: rgba($black, .5) !important;
}

// Active color
.active\:text-body:active {
	color: $body-color !important;
}
.active\:text-white:active {
	color: $white !important;
}
.active\:text-black:active {
	color: $black !important;
}
.active\:text-white-50:active {
	color: rgba($white, .5) !important;
}
.active\:text-black-50:active {
	color: rgba($black, .5) !important;
}
.active\:text-black-50:active {
	color: rgba($black, .5) !important;
}