@mixin bg-cover {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

@mixin bg-overlay {
	position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}