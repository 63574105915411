  .hj-modal {
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    background-color: black;
    padding: 109px 45px;
    outline: none;
    max-width: 435px;
    margin: 0 auto;
    width: 85%;
    min-height: 487px;

    @include media-breakpoint-up(md) {
      max-width: 500px;
      top: 25%;
    }

    .closeModal {
      border: none;
      background: transparent;
      position: absolute;
      right: 10%;
      top: 12%;
      height: 30px;
      width: 30px;
      cursor: pointer;
    }

    .welcome {
      text-align: center;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 269px;

      h3 {
        font-size: 10px;
        font-family: $helvetica_neue;
      }
    }

    h1 {
      font-family: $helvetica_compressed;
      font-size: 32px;
      margin-top: 0;
    }

    label {
      font-size: 9px;
      padding-bottom: 6px;
      display: block;
    }

    input[type="text"] {
      height: 33px;
      border: 1px solid #ffffff;
      background-color: #ffffff;
      width: 100%;
    }

    input[type="submit"],
    button.submitBtn {
      width: 100%;
      height: 53px;
      background-color: #ffffff;
      width: 100%;
      font-family: $helvetica_neue;
      text-transform: uppercase;
      color: #000000;
      font-size: 10px;
      letter-spacing: 2px;
      font-weight: bold;
      margin-top: 30px;
      border: none;
      outline: none;
      line-height: 58px;
    }

    .spinner {
      margin: 100px auto 0;
      width: 70px;
      text-align: center;
    }

    .spinner>span {
      width: 6px;
      height: 6px;
      background-color: #333;
      border: 1px solid black;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sf-btn-load 1.4s infinite ease-in-out both;
      animation: sf-btn-load 1.7s infinite ease-in-out both;
      margin-right: 6px;
    }

    .spinner .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .spinner .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    @-webkit-keyframes sf-btn-load {

      0%,
      80%,
      100% {
        background-color: #fff;
      }

      40% {
        background-color: #000;
      }
    }

    @keyframes sf-btn-load {

      0%,
      80%,
      100% {
        background-color: #fff;
      }

      40% {
        background-color: #000;
      }
    }
  }

  .hj-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }